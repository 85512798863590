<template>

<v-img src="xS7RMf.jpg">
      <v-container>

        <br>
        
         <div class="pt-6">
            <h1 class="text-center display-4 font-weight-thin white--text img-fluid d-none d-xl-flex">Welcome to my Personal Portfolio Website!</h1>
        </div>

        <h1 class="text-center display-3 font-weight-thin img-fluid white--text d-none d-lg-flex d-xl-none">Welcome to my Personal Portfolio Website!</h1>

        <h1 class="text-center display-3 font-weight-thin img-fluid white--text d-none d-md-flex d-lg-none">Welcome to my Personal Portfolio Website!</h1>

        <h1 class="text-center display-2 font-weight-thin img-fluid white--text d-none d-sm-flex d-md-none">Welcome to my Personal Portfolio Website!</h1>        

        <h1 class="text-center font-weight-thin img-fluid d-flex white--text d-sm-none">Welcome to my Personal Portfolio Website!</h1>

        <br>

        <v-row justify="center">

          <v-img max-width="400" src="PCL Headshot-modified.png" elevation="24" contain class="mx-4 mt-14 mb-12 d-none d-sm-flex" eager>
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular
                  color="grey-lighten-4"
                  indeterminate
                ></v-progress-circular>
              </div>
            </template>
          </v-img>

          <v-img max-width="325" src="PCL Headshot-modified.png" elevation="24" contain class="mx-4 mt-6 mb-12 d-flex d-sm-none" eager>
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular
                  color="grey-lighten-4"
                  indeterminate
                ></v-progress-circular>
              </div>
            </template>
          </v-img>
                   
        </v-row>

        <v-row justify="center">

            <h1 class="white--text font-weight-thin display-2 text-center"> 
              <v-avatar color="white" size="55">
                <v-icon x-large color="indigo">
                  mdi-arrow-right-drop-circle
                </v-icon>
              </v-avatar> About Me...</h1>
            
            <v-card elevation="24" class="mx-8 mt-4">  

                <h2 class="py-6 mx-6 my-6 px-6 font-weight-light black--text d-none d-sm-flex">
                  Hi! My name is Benjamin Philipenko, and I am a fifth-year Computer 
                  Engineering student at the University of Victoria in British Columbia, Canada. 
                  I am excited to continue to pursue my passion for engineering and develop my 
                  abilities in my fields of interest. I am passionate about advancing my skills 
                  in the fields of machine learning and artificial intelligence, alongside 
                  developing expertise in cybersecurity systems and advanced software 
                  architecture development. These areas not only captivate my interest but 
                  also drive my commitment to continuous learning and professional growth. I 
                  expect to graduate in August 2024.
                </h2>

                <h3 class="py-6 mx-4 my-6 px-2 font-weight-light black--text d-flex d-sm-none text-center">
                  Hi! My name is Benjamin Philipenko, and I am a fifth-year Computer 
                  Engineering student at the University of Victoria in British Columbia, Canada. 
                  I am excited to continue to pursue my passion for engineering and develop my 
                  abilities in my fields of interest. I am passionate about advancing my skills 
                  in the fields of machine learning and artificial intelligence, alongside 
                  developing expertise in cybersecurity systems and advanced software 
                  architecture development. These areas not only captivate my interest but 
                  also drive my commitment to continuous learning and professional growth. I 
                  expect to graduate in August 2024.   
                </h3>

                <v-row justify="space-around" no-gutters>

                  <v-img max-height="150" max-width="300" src="uvic logo.jpg" class="mb-6 ml-10 d-none d-sm-flex" contain eager></v-img>

                  <v-img max-height="150" max-width="200" src="uvic electrical engineering.png" class="mb-10 d-none d-sm-flex" contain eager></v-img>

                  <v-img max-height="150" max-width="400" src="uvic  robotics.png" class="mb-6 d-none d-sm-flex" contain eager></v-img>

                  <v-img max-width="160" src="uvic logo.jpg" class="mb-6 ml-10 d-flex d-sm-none" contain eager></v-img>

                  <v-img max-width="125" src="uvic electrical engineering.png" class="mb-10 d-flex d-sm-none ml-4 mr-6" contain eager></v-img>

                  <v-img max-width="370" src="uvic  robotics.png" class="mb-6 d-flex d-sm-none" contain eager></v-img>

                </v-row>

            </v-card>
                  
        </v-row>

        



        <v-row class="pt-8" justify="center">

        <v-col md="6">
          <v-card elevation="24" color="indigo darken-4">  

                <h2 class="pt-6 mx-6 mt-6 px-6 font-weight-light white--text text-justify d-none d-xl-flex">
                  As an aspiring professional engineer, I hope to make a meaningful difference 
                  in my community by expanding my knowledge of the technological advances that 
                  are impacting our world today. I've developed a deep appreciation for the effect 
                  that a supportive culture fuelled by strong communication can have on a team through 
                  my wide spectrum of work experience, club and team participation, and development as 
                  a successful athlete. I enjoy connecting and collaborating with others who are looking 
                  to better their communities through new advancements in the tech industry.
                </h2>

                <h2 class="pt-6 mx-6 mt-6 px-6 font-weight-light white--text text-justify d-none d-lg-flex d-xl-none">
                  As an aspiring professional engineer, I hope to make a meaningful difference 
                  in my community by expanding my knowledge of the technological advances that 
                  are impacting our world today. I've developed a deep appreciation for the effect 
                  that a supportive culture fuelled by strong communication can have on a team through 
                  my wide spectrum of work experience, club and team participation, and development as 
                  a successful athlete. I enjoy connecting and collaborating with others who are looking 
                  to better their communities through new advancements in the tech industry.
                </h2>

                <h2 class="pt-6 mx-6 mt-6 px-6 font-weight-light white--text text-justify d-none d-md-flex d-lg-none">
                  As an aspiring professional engineer, I hope to make a meaningful difference 
                  in my community by expanding my knowledge of the technological advances that 
                  are impacting our world today. I've developed a deep appreciation for the effect 
                  that a supportive culture fuelled by strong communication can have on a team through 
                  my wide spectrum of work experience, club and team participation, and development as 
                  a successful athlete. I enjoy connecting and collaborating with others who are looking 
                  to better their communities through new advancements in the tech industry.
                </h2>

                <h3 class="pt-6 mx-6 mt-6 px-6 font-weight-light white--text text-justify d-none d-sm-flex d-md-none">
                  As an aspiring professional engineer, I hope to make a meaningful difference 
                  in my community by expanding my knowledge of the technological advances that 
                  are impacting our world today. I've developed a deep appreciation for the effect 
                  that a supportive culture fuelled by strong communication can have on a team through 
                  my wide spectrum of work experience, club and team participation, and development as 
                  a successful athlete. I enjoy connecting and collaborating with others who are looking 
                  to better their communities through new advancements in the tech industry.
                </h3>

                <h4 class="pt-6 mx-6 mt-6 px-6 font-weight-light white--text text-justify d-sm-none">
                  As an aspiring professional engineer, I hope to make a meaningful difference 
                  in my community by expanding my knowledge of the technological advances that 
                  are impacting our world today. I've developed a deep appreciation for the effect 
                  that a supportive culture fuelled by strong communication can have on a team through 
                  my wide spectrum of work experience, club and team participation, and development as 
                  a successful athlete. I enjoy connecting and collaborating with others who are looking 
                  to better their communities through new advancements in the tech industry.
                </h4>

                <div class="mr-12 pr-12 my-6 pb-6 text-right">

                  <a href="Benjamin Philipenko - Computer Engineering Resume 2023.pdf" download style="text-decoration: none">
                    <v-btn
                      class="ma-2"
                      color="white"
                      rounded
                      large
                      elevation="24"
                    >
                    <v-icon
                      class="mr-1"
                      dark
                    >
                      mdi-download
                    </v-icon>
                      Download Resume PDF
                    </v-btn>
                  </a>
                  
                </div>

            </v-card>
        </v-col>
            

          <v-col md="6" class="pt-12 pb-12">

            <h1 class="white--text display-2 font-weight-light ml-2 text-center mt-12 d-none d-xl-flex">
              I built this website as a place to share my favorite projects! If you have any questions about my experience or projects, please don’t hesitate to reach out!
            </h1>

             <h1 class="white--text display-2 font-weight-light ml-2 text-center pt-12 mt-12 d-none d-lg-flex d-xl-none">
              I built this website as a place to share my favorite projects! If you have any questions about my experience or projects, please don’t hesitate to reach out!
            </h1>

            <h1 class="white--text display-1 font-weight-light ml-2 text-center pt-12 mt-12 d-none d-md-flex d-lg-none">
              I built this website as a place to share my favorite projects! If you have any questions about my experience or projects, please don’t hesitate to reach out!
            </h1>

            <h1 class="white--text display-1 font-weight-light ml-2 text-center pt-12 mt-12 d-none d-sm-flex d-md-none">
              I built this website as a place to share my favorite projects! If you have any questions about my experience or projects, please don’t hesitate to reach out!
            </h1>

            <h1 class="white--text display-1 font-weight-light ml-2 text-center d-sm-none">
              I built this website as a place to share my favorite projects! If you have any questions about my experience or projects, please don’t hesitate to reach out!
            </h1>

            <div class="text-center mt-8 pb-12 mb-12">
            
            <v-btn
                  rounded
                  color="indigo"
                  dark
                  x-large
                  right
                  to="/projects"
                  elevation="24"
                >
                
                  View Projects
                  <v-icon
                  class="ml-1"
                  dark
                >
                  mdi-arrow-right-bold
                </v-icon>
            </v-btn>
            </div>

          </v-col>
          
        </v-row>

      </v-container>
      </v-img>
    
</template>

<script>
  export default {
    name: 'HomePageContent',

    data: () => ({
      
    }),
  }
</script>
