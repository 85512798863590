<template>

  <v-img src="xS7RMf.jpg">
      <v-container>

        <v-sheet
          min-height="80vh"
          max-width="1400px"
          rounded="lg"
          color="grey lighten-5"
          elevation="24"
          class="mx-auto"
        >
        
        <div class="pt-4 pl-4 pb-4">

          <v-row justify="start" class="pt-4 pl-4">
                <v-btn
                color="indigo"
                dark
                large
                to="/projects"
                class="d-none d-xl-flex d-lg-flex d-md-flex d-sm-flex"
                >
                <v-icon
                class="ml-1"
                dark
                >
                mdi-arrow-left-bold
                </v-icon>
                Back to Projects
                </v-btn>
          </v-row>

          <v-row justify="center" class="pb-4">
                <v-btn
                color="indigo"
                dark
                to="/projects"
                class="d-flex d-sm-none"
                >
                <v-icon
                class="ml-1"
                dark
                >
                mdi-arrow-left-bold
                </v-icon>
                Back to Projects
                </v-btn>
            </v-row>
          
        </div>

          <v-divider></v-divider>

          <br>

          <v-row justify="center">
            <h1 class="text-center display-3 black--text font-weight-light d-none d-xl-flex d-lg-flex"> 
                VEX Sensor Cleaning Robot
            </h1>
          </v-row>

          <v-row justify="center">
            <h1 class="text-center display-2 black--text font-weight-light d-none d-md-flex d-lg-none"> 
                VEX Sensor Cleaning Robot
            </h1>
          </v-row>

          <v-row justify="center">
            <h1 class="text-center display-2 black--text font-weight-light d-none d-sm-flex d-md-none"> 
                VEX Sensor Cleaning Robot 
            </h1>
          </v-row>

          <v-row justify="center">
            <h1 class="text-center headline black--text font-weight-light d-flex d-sm-none"> 
                VEX Sensor Cleaning Robot 
            </h1>
          </v-row>
            
          <br>

          <v-divider></v-divider>

          <v-container>

            <h2 class="mx-6 px-6 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  Ocean Networks Canada is a company that provides valuable data and resources in the form of live video feeds of the ocean floor, 
                  updates on water quality, seismology, and biology, and continental movement, enabling researchers around 
                  the globe to push innovations in their field. Occasional falling debris, sedimentation, and biofouling often cover 
                  and obscure the cameras or interfere with sensors, so my team developed an autonomous robot prototype to solve those 
                  problems and prevent further issues in areas that are either difficult or impossible for humans to reach. 
            </h2>
            <h2 class="mx-6 px-6 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  ONC developed their own conceptual designs, such as windshield-wipers, to partially solve the problem. However, they were more
                  interested in the creation of an autonomous robot as it is adaptable to many tasks and issued a request for design proposals 
                  for a small-scale prototype capable of meeting design requirements in a dry lab environment. 
            </h2>
            <h2 class="mx-6 px-6 pb-12 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  The goal for our prototype was to design a robot capable of locating an infrared beacon and correctly positioning itself
                  in front of the sensor and camera arrays that gather data, by analyzing infrared light emmissions. Once properly positioned,
                  the robot places a device on top of the monitoring instruments, which has the purpose of cleaning any biofouling or sedimentation
                  interfering with camera performance and sensor data collection. The robot must notify the system of completion after task execution.
            </h2>

            <h3 class="mx-6 pb-2 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  Ocean Networks Canada is a company that provides valuable data and resources in the form of live video feeds of the ocean floor, 
                  updates on water quality, seismology, and biology, and continental movement, enabling researchers around 
                  the globe to push innovations in their field. Occasional falling debris, sedimentation, and biofouling often cover 
                  and obscure the cameras or interfere with sensors, so my team developed an autonomous robot prototype to solve those 
                  problems and prevent further issues in areas that are either difficult or impossible for humans to reach. 
            </h3>
            <h3 class="mx-6 pb-2 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  ONC developed their own conceptual designs, such as windshield-wipers, to partially solve the problem. However, they were more
                  interested in the creation of an autonomous robot as it is adaptable to many tasks and issued a request for design proposals 
                  for a small-scale prototype capable of meeting design requirements in a dry lab environment.
            </h3>
            <h3 class="mx-6 pb-12 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  The goal for our prototype was to design a robot capable of locating an infrared beacon and correctly positioning itself
                  in front of the sensor and camera arrays that gather data, by analyzing infrared light emmissions. Once properly positioned,
                  the robot places a device on top of the monitoring instruments, which has the purpose of cleaning any biofouling or sedimentation
                  interfering with camera performance and sensor data collection. The robot must notify the system of completion after task execution.
            </h3>

            <h4 class="mx-2 pb-2 font-weight-light black--text text-justify d-flex d-sm-none">
                  Ocean Networks Canada is a company that provides valuable data and resources in the form of live video feeds of the ocean floor, 
                  updates on water quality, seismology, and biology, and continental movement, enabling researchers around 
                  the globe to push innovations in their field. Occasional falling debris, sedimentation, and biofouling often cover 
                  and obscure the cameras or interfere with sensors, so my team developed an autonomous robot prototype to solve those 
                  problems and prevent further issues in areas that are either difficult or impossible for humans to reach.
            </h4>
            <h4 class="mx-2 pb-2 font-weight-light black--text text-justify d-flex d-sm-none">
                  ONC developed their own conceptual designs, such as windshield-wipers, to partially solve the problem. However, they were more
                  interested in the creation of an autonomous robot as it is adaptable to many tasks and issued a request for design proposals 
                  for a small-scale prototype capable of meeting design requirements in a dry lab environment.
            </h4>
            <h4 class="mx-2 pb-12 font-weight-light black--text text-justify d-flex d-sm-none">
                  The goal for our prototype was to design a robot capable of locating an infrared beacon and correctly positioning itself
                  in front of the sensor and camera arrays that gather data, by analyzing infrared light emmissions. Once properly positioned,
                  the robot places a device on top of the monitoring instruments, which has the purpose of cleaning any biofouling or sedimentation
                  interfering with camera performance and sensor data collection. The robot must notify the system of completion after task execution.
            </h4>

            <v-row justify="center">
              <iframe eager class="align-center d-none d-xl-flex d-lg-flex" width="800" height="450" src="https://www.youtube.com/embed/gYytB74NFd8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <iframe eager class="align-center d-none d-md-flex d-lg-none" width="560" height="315" src="https://www.youtube.com/embed/gYytB74NFd8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <iframe eager class="align-center d-none d-sm-flex d-md-none" width="560" height="315" src="https://www.youtube.com/embed/gYytB74NFd8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <iframe eager class="align-center d-flex d-sm-none" width="480" height="275" src="https://www.youtube.com/embed/gYytB74NFd8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </v-row>

                <h2 class="mx-6 px-6 pt-12 pb-12 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  Our robot was programmed using C and RobotC. Our Robot successfully completed the required objectives and all prototypes
                  were donated to ONC by the University of Victoria Engineering Faculty at the end of the semester. 
                </h2>

                <div class="pb-6 d-none d-xl-flex d-lg-flex">
                  <v-card
                    class="mx-auto"
                    max-width="600"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="vex robot.jpg"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>

                <div class="pb-6 d-none d-md-flex d-lg-none">
                  <v-card
                    class="mx-auto"
                    max-width="600"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="vex robot.jpg"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>

                


                <h3 class="mx-6 pt-12 pb-12 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  Our robot was programmed using C and RobotC. Our Robot successfully completed the required objectives and all prototypes
                  were donated to ONC by the University of Victoria Engineering Faculty at the end of the semester.  
                </h3>

                <div class="pb-6 d-none d-sm-flex d-md-none">
                  <v-card
                    class="mx-auto"
                    max-width="400"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="vex robot.jpg"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>




                <h4 class="mx-2 pt-12 pb-12 font-weight-light black--text text-justify d-flex d-sm-none">
                  Our robot was programmed using C and RobotC. Our Robot successfully completed the required objectives and all prototypes
                  were donated to ONC by the University of Victoria Engineering Faculty at the end of the semester.  
                </h4>

                <div class="pb-6 d-flex d-sm-none">
                  <v-card
                    class="mx-auto"
                    max-width="325"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="vex robot.jpg"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>

          </v-container>

        </v-sheet>

      </v-container>
  </v-img>

</template>

<script>
  export default {
    name: 'Project4Content',

    data: () => ({
      
    }),
  }
</script>