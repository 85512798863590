<template>

  <v-img src="xS7RMf.jpg">
      <v-container>

        <v-sheet
          min-height="80vh"
          max-width="1400px"
          rounded="lg"
          color="grey lighten-5"
          elevation="24"
          class="mx-auto"
        >
        
        <div class="pt-4 pl-4 pb-4">

          <v-row justify="start" class="pt-4 pl-4">
                <v-btn
                color="indigo"
                dark
                large
                to="/projects"
                class="d-none d-xl-flex d-lg-flex d-md-flex d-sm-flex"
                >
                <v-icon
                class="ml-1"
                dark
                >
                mdi-arrow-left-bold
                </v-icon>
                Back to Projects
                </v-btn>
          </v-row>

          <v-row justify="center" class="pb-4">
                <v-btn
                color="indigo"
                dark
                to="/projects"
                class="d-flex d-sm-none"
                >
                <v-icon
                class="ml-1"
                dark
                >
                mdi-arrow-left-bold
                </v-icon>
                Back to Projects
                </v-btn>
            </v-row>
          
        </div>

          <v-divider></v-divider>

          <br>

          <v-row justify="center">
            <h1 class="text-center display-3 black--text font-weight-light d-none d-xl-flex d-lg-flex"> 
                2001 Kawasaki KX125 
            </h1>
          </v-row>

          <v-row justify="center">
            <h1 class="text-center display-2 black--text font-weight-light d-none d-md-flex d-lg-none"> 
                2001 Kawasaki KX125 
            </h1>
          </v-row>

          <v-row justify="center">
            <h1 class="text-center display-2 black--text font-weight-light d-none d-sm-flex d-md-none"> 
                2001 Kawasaki KX125 
            </h1>
          </v-row>

          <v-row justify="center">
            <h1 class="text-center headline black--text font-weight-light d-flex d-sm-none"> 
                2001 Kawasaki KX125 
            </h1>
          </v-row>
            
          <br>

          <v-divider></v-divider>

          <v-container>

            <h2 class="mx-6 px-6 pb-12 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  In this video I removed and replaced the piston/piston rings of my 2001 Kawasaki KX125 2 Stroke dirt bike. 
                  I also inspected the cylinder and cylinder head for wear and damage. My dirt bike had low compression and hard start, 
                  so I decided to perform a top-end rebuild.
            </h2>

            <h3 class="mx-6 pb-12 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  In this video I removed and replaced the piston/piston rings of my 2001 Kawasaki KX125 2 Stroke dirt bike. 
                  I also inspected the cylinder and cylinder head for wear and damage. My dirt bike had low compression and hard start, 
                  so I decided to perform a top-end rebuild.
            </h3>

            <h4 class="mx-2 pb-8 font-weight-light black--text text-justify d-flex d-sm-none">
                  In this video I removed and replaced the piston/piston rings of my 2001 Kawasaki KX125 2 Stroke dirt bike. 
                  I also inspected the cylinder and cylinder head for wear and damage. My dirt bike had low compression and hard start, 
                  so I decided to perform a top-end rebuild.
            </h4>

            <v-row justify="center">
              <iframe eager class="align-center d-none d-xl-flex d-lg-flex" width="800" height="450" src="https://www.youtube.com/embed/R9VWbu8CWDU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <iframe eager class="align-center d-none d-md-flex d-lg-none" width="560" height="315" src="https://www.youtube.com/embed/R9VWbu8CWDU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <iframe eager class="align-center d-none d-sm-flex d-md-none" width="560" height="315" src="https://www.youtube.com/embed/R9VWbu8CWDU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <iframe eager class="align-center d-flex d-sm-none" width="480" height="275" src="https://www.youtube.com/embed/R9VWbu8CWDU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </v-row>

                <h2 class="mx-6 px-6 pt-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  In my spare time one of my favourite activities is to ride and maintain my dirt bike at my family cabin. 
                  I have a ton of fun getting out onto the trails and doing maintenance to improve the performance of my dirt bike. 
                  My dirt bike has a 2-stroke engine, so combustion takes place with each revolution of the crankshaft, which results in greater power output 
                  than a 4-stroke engine and more instantaneous delivery. It doesn’t have any valves, which provides the advantage of reduced weight. 2-stroke engines use a 
                  lot more fuel then 4-strokes, and they also wear out much quicker. 
                  This keeps me extra busy! Some essential routine maintenance procedures I perform include: 
                </h2>

                <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  • Inspecting and thoroughly cleaning the air filter and airbox for harmful particles and moisture before and after a ride. 
                  I also ensure that my filter has complete oil coverage with the proper amount of oil to avoid oil weeps into the engine 
                  and prevent contaminants from passing through the intake. I use a dual-stage, bonded foam filter. 
                </h2>
                
                <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  • Carburetor care and maintenance. Evaporated gas leaves residue that can plug the jets. Occasionally this maintenance involves jetting my carburetor. 
                </h2>

                 <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  • Inspecting the spark plug and spark plug maintenance. This is a good indicator of the running condition of a 
                  dirt bike’s engine. Bad spark plugs can interfere with performance. 
                </h2>

                <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  • Cleaning and lubing cables. This is an essential safety check as the cables are the main connections to a 
                  large majority of the controls on a dirt bike. I ensure to clean and lubricate the clutch and throttle cables 
                  and the front brake lines after a lot of riding.   
                </h2>

                <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  • Replacing and inspecting the brake pads when there is approximately 1mm of the pad remaining. This avoids 
                  dangerous situations with faulty brake performance and damage to the brake rotor.    
                </h2>

                <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  • Replenishing fluids before and after rides.     
                </h2>

                <h2 class="mx-12 px-12 pb-6 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  • Measuring and adjusting chain tension to avoid my chain hopping off the sprocket.    
                </h2>

                <div class="pb-6 d-none d-xl-flex d-lg-flex">
                  <v-card
                    class="mx-auto"
                    max-width="550"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="dirtbike photo cabin.JPG"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>

                <div class="pb-6 d-none d-md-flex d-lg-none">
                  <v-card
                    class="mx-auto"
                    max-width="400"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="dirtbike photo cabin.JPG"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>

                


                <h3 class="mx-6 pt-8 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  In my spare time one of my favourite activities is to ride and maintain my dirt bike at my family cabin. 
                  I have a ton of fun getting out onto the trails and doing maintenance to improve the performance of my dirt bike. 
                  My dirt bike has a 2-stroke engine, so combustion takes place with each revolution of the crankshaft, which results in greater power output 
                  than a 4-stroke engine and more instantaneous delivery. It doesn’t have any valves, which provides the advantage of reduced weight. 2-stroke engines use a 
                  lot more fuel then 4-strokes, and they also wear out much quicker.
                  This keeps me extra busy! Some essential routine maintenance procedures I perform include: 
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  • Inspecting and thoroughly cleaning the air filter and airbox for harmful particles and moisture 
                  before and after a ride. I also ensure that my filter has complete oil coverage with the proper 
                  amount of oil to avoid oil weeps into the engine and prevent contaminants from passing through 
                  the intake. I use a dual-stage, bonded foam filter. 
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  • Carburetor care and maintenance. Evaporated gas leaves residue that can plug the jets. Occasionally this maintenance involves jetting my carburetor. 
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  • Inspecting the spark plug and spark plug maintenance. This is a good indicator of the running condition of a 
                  dirt bike’s engine. Bad spark plugs can interfere with performance. 
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  • Cleaning and lubing cables. This is an essential safety check as the cables are the main connections to a 
                  large majority of the controls on a dirt bike. I ensure to clean and lubricate the clutch and throttle cables 
                  and the front brake lines after a lot of riding.
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  • Replacing and inspecting the brake pads when there is approximately 1mm of the pad remaining. This avoids 
                  dangerous situations with faulty brake performance and damage to the brake rotor.
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  • Replenishing fluids before and after rides. 
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  •  Measuring and adjusting chain tension to avoid my chain hopping off the sprocket. 
                </h3>

                <div class="pb-6 d-none d-sm-flex d-md-none">
                  <v-card
                    class="mx-auto"
                    max-width="400"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="dirtbike photo cabin.JPG"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>







                <h4 class="mx-2 pt-8 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  In my spare time one of my favourite activities is to ride and maintain my Dirt bike at my family cabin. 
                  I have a ton of fun getting out onto the trails and doing maintenance to improve the performance of my dirt bike. 
                  My dirt bike has a 2-stroke engine, so combustion takes place with each revolution of the crankshaft, which results in greater power output 
                  than a 4-stroke engine and more instantaneous delivery. It doesn’t have any valves, which provides the advantage of reduced weight. 2-stroke engines use a 
                  lot more fuel then 4-strokes, and they also wear out much quicker. 
                  This keeps me extra busy! Some essential routine maintenance procedures I perform include: 
                </h4>

                <h4 class="mx-2 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  • Inspecting and thoroughly cleaning the air filter and airbox for harmful particles and moisture 
                  before and after a ride. I also ensure that my filter has complete oil coverage with the proper 
                  amount of oil to avoid oil weeps into the engine and prevent contaminants from passing through 
                  the intake. I use a dual-stage, bonded foam filter. 
                </h4>

                <h4 class="mx-2 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  • Carburetor care and maintenance. Evaporated gas leaves residue that can plug the jets. Occasionally this maintenance involves jetting my carburetor. 
                </h4>

                <h4 class="mx-2 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  • Inspecting the spark plug and spark plug maintenance. This is a good indicator of the running condition of a 
                  dirt bike’s engine. Bad spark plugs can interfere with performance. 
                </h4>

                <h4 class="mx-2 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  • Cleaning and lubing cables. This is an essential safety check as the cables are the main connections to a 
                  large majority of the controls on a dirt bike. I ensure to clean and lubricate the clutch and throttle cables 
                  and the front brake lines after a lot of riding.
                </h4>

                <h4 class="mx-2 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  • Replacing and inspecting the brake pads when there is approximately 1mm of the pad remaining. This avoids 
                  dangerous situations with faulty brake performance and damage to the brake rotor.
                </h4>

                <h4 class="mx-2 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  • Replenishing fluids before and after rides.
                </h4>

                <h4 class="mx-2 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  •  Measuring and adjusting chain tension to avoid my chain hopping off the sprocket. 
                </h4>

                <div class="pb-6 d-flex d-sm-none">
                  <v-card
                    class="mx-auto"
                    max-width="325"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="dirtbike photo cabin.JPG"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>

          </v-container>

        </v-sheet>

      </v-container>
  </v-img>

</template>

<script>
  export default {
    name: 'Project1Content',

    data: () => ({
      
    }),
  }
</script>