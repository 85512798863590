<template>

  <v-img src="xS7RMf.jpg">
      <v-container>

        <v-sheet
          min-height="80vh"
          max-width="1400px"
          rounded="lg"
          color="grey lighten-5"
          elevation="24"
          class="mx-auto"
        >
        
        <div class="pt-4 pl-4 pb-4">

          <v-row justify="start" class="pt-4 pl-4">
                <v-btn
                color="indigo"
                dark
                large
                to="/projects"
                class="d-none d-xl-flex d-lg-flex d-md-flex d-sm-flex"
                >
                <v-icon
                class="ml-1"
                dark
                >
                mdi-arrow-left-bold
                </v-icon>
                Back to Projects
                </v-btn>
          </v-row>

          <v-row justify="center" class="pb-4">
                <v-btn
                color="indigo"
                dark
                to="/projects"
                class="d-flex d-sm-none"
                >
                <v-icon
                class="ml-1"
                dark
                >
                mdi-arrow-left-bold
                </v-icon>
                Back to Projects
                </v-btn>
            </v-row>
          
        </div>

          <v-divider></v-divider>

          <br>

          <v-row justify="center">
            <h1 class="text-center display-3 black--text font-weight-light d-none d-xl-flex d-lg-flex"> 
                Centrifugal Clutch
            </h1>
          </v-row>

          <v-row justify="center">
            <h1 class="text-center display-2 black--text font-weight-light d-none d-md-flex d-lg-none"> 
                Centrifugal Clutch 
            </h1>
          </v-row>

          <v-row justify="center">
            <h1 class="text-center display-2 black--text font-weight-light d-none d-sm-flex d-md-none"> 
                Centrifugal Clutch
            </h1>
          </v-row>

          <v-row justify="center">
            <h1 class="text-center headline black--text font-weight-light d-flex d-sm-none"> 
                Centrifugal Clutch
            </h1>
          </v-row>
            
          <br>

          <v-divider></v-divider>

          <v-container>

            <h2 class="mx-6 px-6 pb-12 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  For this project, I was a team lead in a project design team where our objective was to design a small 
                  centrifugal clutch suitable for transmitting power between an electric motor and load. This system was 
                  required to utilize a drive belt and tensioning system. As a team, we generated a complete set of shop 
                  drawings for the components that required to be machined. We also calculated required materials and costs 
                  for product development. The software we used to create our engineering drawings and bill of materials 
                  was SolidWorks. 
            </h2>

            <h3 class="mx-6 pb-12 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  For this project, I was a team lead in a project design team where our objective was to design a small 
                  centrifugal clutch suitable for transmitting power between an electric motor and load. This system was 
                  required to utilize a drive belt and tensioning system. As a team, we generated a complete set of shop 
                  drawings for the components that required to be machined. We also calculated required materials and costs 
                  for product development. The software we used to create our engineering drawings and bill of materials 
                  was SolidWorks. 
            </h3>

            <h4 class="mx-2 pb-8 font-weight-light black--text text-justify d-flex d-sm-none">
                  For this project, I was a team lead in a project design team where our objective was to design a small 
                  centrifugal clutch suitable for transmitting power between an electric motor and load. This system was 
                  required to utilize a drive belt and tensioning system. As a team, we generated a complete set of shop 
                  drawings for the components that required to be machined. We also calculated required materials and costs 
                  for product development. The software we used to create our engineering drawings and bill of materials 
                  was SolidWorks. 
            </h4>

            <!-- <v-row justify="center">
                <embed class="align-center d-none d-xl-flex d-lg-flex" width="1150" height="800" src="MECH200_Project3_GROUP4.pdf" type="application/pdf">
                <embed class="align-center d-none d-md-flex d-lg-none" width="850" height="800" src="MECH200_Project3_GROUP4.pdf" type="application/pdf">
                <embed class="align-center d-none d-sm-flex d-md-none" width="800" height="600" src="MECH200_Project3_GROUP4.pdf" type="application/pdf">
                <embed class="align-center d-flex d-sm-none" width="100" height="200" src="MECH200_Project3_GROUP4.pdf" type="application/pdf">
            </v-row> -->

            <v-row justify="center">
              <embed class="align-center d-none d-xl-flex d-lg-flex" width="1150" height="800" src="MECH200_Project3_GROUP4.pdf" type="application/pdf">
              <embed class="align-center d-none d-md-flex d-lg-none" width="850" height="800" src="MECH200_Project3_GROUP4.pdf" type="application/pdf">
              <v-img
                  src="Clutch Assembly Drawing_page-0001.jpg"
                  eager
                  contain
                  class="align-center hidden-md-and-up"
                >
              </v-img>     

                <a class="pt-4 hidden-md-and-up" href="MECH200_Project3_GROUP4.pdf" download style="text-decoration: none">
                  <v-btn
                    color="indigo"
                    rounded
                    large
                    dark
                  >
                  <v-icon
                    class="mr-1"
                    dark
                  >
                    mdi-download
                  </v-icon>
                    Download Project Report
                  </v-btn>
                </a>

            </v-row>

                <h2 class="mx-6 px-6 pt-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                 The design specifications and constraints on our project required that our design adhered to the following:
                </h2>

                <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  • The motor used is rated at 280W at a speed of 710 rpm (rev/min). 
                </h2>
                
                <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  • The drive shaft diameter is 0.75 in, 1.75 in long, and has a keyway suitable for a 3/16 in square key.  
                </h2>

                 <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  • The height from the base to the center of the motor’s shaft is 4.5 in.
                </h2>

                <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  • The clutch engages at 65% of the motor’s full speed.    
                </h2>

                <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                  • The clutch works in either direction of motor rotation.   
                </h2>

                <h2 class="mx-12 px-12 pb-12 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                   • The drive pulley is compatible with a 4L V-belt.    
                </h2>

                <div class="pb-12 d-none d-xl-flex d-lg-flex">
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-width="500"
                  >
                    <v-img
                      src="clutch1.jpg"
                      eager
                      contain
                    >
                    </v-img>
                  </v-card>
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-width="575"
                  >
                    <v-img
                      src="clutch2.jpg"
                      eager
                      contain
                    >
                    </v-img>
                  </v-card>
                </div>

                <div class="pb-6 d-none d-md-flex d-lg-none">
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-width="350"
                  >
                    <v-img
                      src="clutch1.jpg"
                      eager
                      contain
                      
                    >
                    </v-img>
                  </v-card>
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-width="400"
                  >
                    <v-img
                      src="clutch2.jpg"
                      eager
                      contain
                      
                    >
                    </v-img>
                  </v-card>
                </div>

                


                <h3 class="mx-6 pt-8 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                 The design specifications and constraints on our project required that our design adhered to the following:
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                 • The motor used is rated at 280W at a speed of 710 rpm (rev/min).
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  • The drive shaft diameter is 0.75 in, 1.75 in long, and has a keyway suitable for a 3/16 in square key. 
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  • The height from the base to the center of the motor’s shaft is 4.5 in.
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  • The clutch engages at 65% of the motor’s full speed. 
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  • The clutch works in either direction of motor rotation.
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                   • The drive pulley is compatible with a 4L V-belt.
                </h3>

                <div class="pb-6 d-none d-sm-flex d-md-none">
                  <v-card
                    class="mx-auto"
                    elevation="12"
                  >
                    <v-img
                      src="clutch1.jpg"
                      eager
                      contain
                      max-width="235"
                    >
                    </v-img>
                  </v-card>
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-width="270"
                  >
                    <v-img
                      src="clutch2.jpg"
                      eager
                      contain
                    >
                    </v-img>
                  </v-card>
                </div>







                <h4 class="mx-2 pt-8 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  The design specifications and constraints on our project required that our design adhered to the following:
                </h4>

                <h4 class="mx-2 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  • The motor used is rated at 280W at a speed of 710 rpm (rev/min).
                </h4>

                <h4 class="mx-2 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  • The drive shaft diameter is 0.75 in, 1.75 in long, and has a keyway suitable for a 3/16 in square key. 
                </h4>

                <h4 class="mx-2 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  • The height from the base to the center of the motor’s shaft is 4.5 in.
                </h4>

                <h4 class="mx-2 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  • The clutch engages at 65% of the motor’s full speed. 
                </h4>

                <h4 class="mx-2 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  • The clutch works in either direction of motor rotation.
                </h4>

                <h4 class="mx-2 pb-4 font-weight-light black--text text-justify d-flex d-sm-none">
                  • The drive pulley is compatible with a 4L V-belt.
                </h4>

                <div class="pb-6 d-flex d-sm-none">
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-width="150"
                  >
                    <v-img
                      src="clutch1.jpg"
                      eager
                      contain
                    >
                    </v-img>
                  </v-card>
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-width="175"
                  >
                    <v-img
                      src="clutch2.jpg"
                      eager
                      contain
                    >
                    </v-img>
                  </v-card>
                </div>

          </v-container>

        </v-sheet>

      </v-container>
  </v-img>

</template>

<script>

  export default {
    name: 'Project1Content',

    data: () => ({
      
    }),
  }
</script>