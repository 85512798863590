<template>
  <div>
    <Project1Content />
  </div>
</template>

<script>
// @ is an alias to /src
import Project1Content from '@/components/Project1Content.vue'

export default {
  name: 'Project1',
  components: {
    Project1Content
  }
}
</script>
