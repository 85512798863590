<template>
  <div>
    <ProjectsContent />
  </div>
</template>

<script>
// @ is an alias to /src
import ProjectsContent from '@/components/ProjectsContent.vue'

export default {
  name: 'Projects',
  components: {
    ProjectsContent
  }
}
</script>
