import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage'
import Projects from '../views/Projects'
import Contact from '../views/Contact'
import Project1 from '../views/Project1'
import Project2 from '../views/Project2'
import Project3 from '../views/Project3'
import Project4 from '../views/Project4'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/project1',
    name: 'Project1',
    component: Project1
  },
  {
    path: '/project2',
    name: 'Project2',
    component: Project2
  },
  {
    path: '/project3',
    name: 'Project3',
    component: Project3
  },
  {
    path: '/project4',
    name: 'Project4',
    component: Project4
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
}
})

export default router
