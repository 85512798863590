<template>
  <div>
    <Project4Content />
  </div>
</template>

<script>
// @ is an alias to /src
import Project4Content from '@/components/Project4Content.vue'

export default {
  name: 'Project4',
  components: {
    Project4Content
  }
}
</script>