<template>
  <div>
    <HomePageContent />
  </div>
</template>

<script>
// @ is an alias to /src
import HomePageContent from '@/components/HomePageContent.vue'

export default {
  name: 'HomePage',
  components: {
    HomePageContent
  }
}
</script>
