<template>

  <v-img src="xS7RMf.jpg">
      <v-container>

        <br>
        <br>

        <v-sheet
          min-height="80vh"
          max-width="1400px"
          rounded="lg"
          color="grey lighten-5"
          elevation="24"
          class="mx-auto"
        >

          <br>

          <v-row justify="center" class="d-none d-xl-flex d-lg-flex d-md-flex d-sm-flex">
            <h1 class="display-4 black--text font-weight-thin"> 
              <v-avatar color="indigo" size="60">
                <v-icon x-large color="white">
                  mdi-semantic-web
                </v-icon>
              </v-avatar> Projects</h1>
          </v-row>

          <v-row justify="center" class=" d-flex d-sm-none">
            <h1 class="display-2 black--text font-weight-thin"> 
              <v-avatar color="indigo" size="45">
                <v-icon large color="white">
                  mdi-semantic-web
                </v-icon>
              </v-avatar> Projects</h1>
          </v-row>

          <br>

          <v-divider></v-divider>

          <v-container class="my-10 pb-12">

            <v-row>
              <v-col>

                <v-card
                  class="mx-auto mb-4"
                  max-width="600"
                  >

                  <v-hover>
                    <template v-slot:default="{ hover }">
                    <v-img
                      class="align-end"
                      height="400px"
                      src="2002-Kawasaki-KX125-Green-0.jpg"
                    >
                    <v-fade-transition>
                      <v-overlay
                        v-if="hover"
                        absolute
                        color="#5C6BC0"
                      >
                        <v-btn to="/project1">View Project</v-btn>
                      </v-overlay>
                    </v-fade-transition>
                    </v-img>
                    </template>
                  </v-hover>

                    <v-expansion-panels popout flat>
                      <v-expansion-panel
                      >
                        <v-expansion-panel-header class="headline font-weight-regular d-none d-xl-flex d-lg-flex d-md-flex d-sm-flex">
                          2001 Kawasaki KX125                    
                        </v-expansion-panel-header>

                        <v-expansion-panel-header class="title font-weight-regular d-flex d-sm-none">
                          2001 Kawasaki KX125                    
                        </v-expansion-panel-header>
                        
                        <v-expansion-panel-content class="text-primary">

                          <div>
                            Check out my dirt bike! I love to talk about motorcycles and riding any chance I get. 
                            In this section, I showcase my current bike and discuss some of the maintenance/repairs 
                            I’ve completed on it.
                          </div>

                          <div class="text-right">
                            <v-btn
                                class="ma-2"
                                dark
                                text
                                to="/project1"
                                color="indigo"
                              >
                                View Project
                                <v-icon
                                class="ml-1"
                                dark
                              >
                                mdi-arrow-right-bold
                              </v-icon>
                              </v-btn>
                          </div>
                              
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    
                </v-card>
                
              </v-col>

             <v-col>

              <v-card
              class="mx-auto mb-4"
              max-width="600"
              >
              
              <v-hover>
                <template v-slot:default="{ hover }">
                <v-img
                  class="align-end"
                  height="400px"
                  src="Completed clutch assembly thumbnail.jpg"
                >
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#5C6BC0"
                  >
                    <v-btn to="/project2">View Project</v-btn>
                  </v-overlay>
                </v-fade-transition>
                </v-img>
                </template>
              </v-hover>

                <v-expansion-panels popout flat>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header class="headline font-weight-regular d-none d-xl-flex d-lg-flex d-md-flex d-sm-flex">
                      Centrifugal Clutch                   
                    </v-expansion-panel-header>

                    <v-expansion-panel-header class="title font-weight-regular d-flex d-sm-none">
                      Centrifugal Clutch                  
                    </v-expansion-panel-header>
                    
                    <v-expansion-panel-content class="text-primary">

                      <div>
                        For this project, my team had to use applied physics and implement 
                        centrifugal force calculations to design an automatic centrifugal clutch. 
                        A centrifugal clutch is an automatic clutch that operates on the principle 
                        that when the rotational speed is low, the output shaft is disengaged; 
                        as the rotational speed increases, the output shaft increasingly engages. 
                        In the design process, we produced a complete set of shop drawings for 
                        our design and a bill of materials. 
                      </div>

                      <div class="text-right">
                        <v-btn
                            class="ma-2"
                            dark
                            text
                            to="/project2"
                            color="indigo"
                          >
                            View Project
                            <v-icon
                            class="ml-1"
                            dark
                          >
                            mdi-arrow-right-bold
                          </v-icon>
                          </v-btn>
                      </div>
                          
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                
            </v-card>
            
            </v-col>

            <v-col>

              <v-card
              class="mx-auto mb-4"
              max-width="600"
              >
              
              <v-hover>
                <template v-slot:default="{ hover }">
                <v-img
                  class="align-end"
                  height="400px"
                  src="rover_3d.png"
                >
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#5C6BC0"
                  >
                    <v-btn to="/project3">View Project</v-btn>
                  </v-overlay>
                </v-fade-transition>
                </v-img>
                </template>
              </v-hover>

                <v-expansion-panels popout flat>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header class="headline font-weight-regular d-none d-xl-flex d-lg-flex d-md-flex d-sm-flex">
                      Robotics Club Rover Development
                    </v-expansion-panel-header>

                    <v-expansion-panel-header class="title font-weight-regular d-flex d-sm-none">
                      Robotics Club Rover Development              
                    </v-expansion-panel-header>
                    
                    <v-expansion-panel-content class="text-primary">

                      <div>
                        I am a member of the UVic Robotics Club, which is a diverse group of open-minded engineering students who are passionate about 
                        designing, building, and programming robots. The club's main goal is to build a rover to compete in the 
                        2022 Canadian International Rover Challenge (CIRC).
                      </div>

                      <div class="text-right">
                        <v-btn
                            class="ma-2"
                            dark
                            text
                            to="/project3"
                            color="indigo"
                          >
                            View Project
                            <v-icon
                            class="ml-1"
                            dark
                          >
                            mdi-arrow-right-bold
                          </v-icon>
                          </v-btn>
                      </div>
                          
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                
            </v-card>
            
            </v-col>

             <v-col>

              <v-card
              class="mx-auto mb-4"
              max-width="600"
              >
              
              <v-hover>
                <template v-slot:default="{ hover }">
                <v-img
                  class="align-end"
                  height="400px"
                  src="ONC underwater cameras.png"
                >
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#5C6BC0"
                  >
                    <v-btn to="/project4">View Project</v-btn>
                  </v-overlay>
                </v-fade-transition>
                </v-img>
                </template>
              </v-hover>

                <v-expansion-panels popout flat>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header class="headline font-weight-regular d-none d-xl-flex d-lg-flex d-md-flex d-sm-flex">
                      VEX Sensor Cleaning Robot  
                    </v-expansion-panel-header>

                    <v-expansion-panel-header class="title font-weight-regular d-flex d-sm-none">
                      VEX Sensor Cleaning Robot                   
                    </v-expansion-panel-header>
                    
                    <v-expansion-panel-content class="text-primary">

                      <div>
                        In an engineering design class, I worked in a team to respond to Ocean Networks Canada's (ONC) requested design
                        proposals for an autonomous underwater robot. Our team worked to complete the electrical, mechanical and software components
                        of robot construction. At the end of the semester, the University of Victoria Engineering Faculty donated the prototypes generated
                        to ONC for the purpose of cleaning underwater cameras and sensors.
                      </div>

                      <div class="text-right">
                        <v-btn
                            class="ma-2"
                            dark
                            text
                            to="/project4"
                            color="indigo"
                          >
                            View Project
                            <v-icon
                            class="ml-1"
                            dark
                          >
                            mdi-arrow-right-bold
                          </v-icon>
                          </v-btn>
                      </div>
                          
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                
            </v-card>
            
            </v-col>

            

            <!-- <v-col>
              <v-card
              class="mx-auto"
              max-width="600"
            >
              <v-img
                class="white--text align-end"
                height="400px"
                src="Completed clutch assembly thumbnail.jpg"
              >
                
              </v-img>
                
                <v-card-title class="headline font-weight-light">Centrifugal Clutch</v-card-title>

              <v-card-text class="text--primary">

                <div>Lorem Ipsum Who Cares For Now</div>

              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="orange"
                  text
                >
                  Share
                </v-btn>

                <v-btn
                  color="orange"
                  text
                >
                  Explore
                </v-btn>
              </v-card-actions>
            </v-card>
            </v-col> -->
            </v-row>

          </v-container>

        </v-sheet>

      </v-container>
  </v-img>

</template>

<script>
  export default {
    name: 'ProjectsContent',

    data: () => ({
      
    }),
  }
</script>