<template>
  <div>
    <Project3Content />
  </div>
</template>

<script>
// @ is an alias to /src
import Project3Content from '@/components/Project3Content.vue'

export default {
  name: 'Project3',
  components: {
    Project3Content
  }
}
</script>