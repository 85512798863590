<template>

<v-img src="xS7RMf.jpg">
      <v-container>

        <br>
        <br>

        <v-card elevation="24" max-width="1200" class="mx-auto d-none d-sm-flex mb-12" color="grey lighten-5">  

          <div class="pa-6">

                <h1 class="text-center display-3 font-weight-thin pb-2"> 
                <v-avatar color="indigo" size="60">
                  <v-icon large color="white">
                    mdi-account-box-multiple
                  </v-icon>
                </v-avatar> Let's Connect!</h1>

                <v-divider></v-divider>

                <h2 class="pt-6 display-1 font-weight-light text-center">
                  I am always looking to grow my skillset, work on meaningful
                  projects, and engage with others who share the same passions as I do. 
                  If you have any questions or want to chat, feel free to contact me 
                  on any of the platforms below!
                </h2>

                <br>
                <br>

                <v-row class="justify-center">

                  <v-tooltip top class="justify-center">
                    <template v-slot:activator="{ on, attrs }">
                      <a href="mailto:benjaminphilipenko@gmail.com" style="text-decoration:none; color:inherit" link v-bind="attrs" v-on="on">
                        <!-- <v-avatar color="indigo" size="65"> -->
                          <v-icon x-large class="display-4 pl-6 pr-6">
                            mdi-email
                          </v-icon>
                        <!-- </v-avatar> -->
                      </a>
                    </template>
                    <span>Email Me!</span>
                  </v-tooltip>

                  <v-tooltip top class="justify-center">
                    <template v-slot:activator="{ on, attrs }">
                      <a href="https://www.linkedin.com/in/benjamin-philipenko-9145231b8/" style="text-decoration:none; color:inherit" link v-bind="attrs" v-on="on">
                        <!-- <v-avatar color="indigo" size="65"> -->
                          <v-icon x-large class="display-4 pl-6 pr-6">
                            mdi-linkedin
                          </v-icon>
                        <!-- </v-avatar> -->
                      </a>
                    </template>
                    <span>Check Out My LinkedIn Profile</span>
                  </v-tooltip>

                  <!-- <v-tooltip top class="justify-center">
                    <template v-slot:activator="{ on, attrs }">
                      <a href="https://github.com/Benjamin-Philipenko" style="text-decoration:none; color:inherit" link v-bind="attrs" v-on="on">
                          <v-icon x-large class="display-4 pl-6 pr-6">
                            mdi-github
                          </v-icon>
                      </a>
                    </template>
                    <span>Check Out My Github Projects</span>
                  </v-tooltip> -->

                </v-row>

                <br>

          </div>

            </v-card>

            <v-card elevation="24" max-width="1200" class="mx-auto d-flex d-sm-none" color="grey lighten-5">  

          <div class="pa-6">

                <h1 class="text-center display-1 font-weight-thin pb-2"> 
                <v-avatar color="indigo" size="60">
                  <v-icon large color="white">
                    mdi-account-box-multiple
                  </v-icon>
                </v-avatar> Let's Connect!</h1>

                <v-divider></v-divider>

                <h3 class="pt-6 font-weight-light text-center">
                  I am always looking to grow my skillset, work on meaningful
                  projects, and engage with others who share the same passions as I do. 
                  If you have any questions or want to chat, feel free to contact me 
                  on any of the platforms below!
                </h3>

                <br>
                <br>

                <v-row class="justify-center">

                  <v-tooltip top class="justify-center">
                    <template v-slot:activator="{ on, attrs }">
                      <a href="mailto:benjaminphilipenko@gmail.com" style="text-decoration:none; color:inherit" link v-bind="attrs" v-on="on">
                        <!-- <v-avatar color="indigo" size="65"> -->
                          <v-icon x-large class="display-3 pl-6 pr-6">
                            mdi-email
                          </v-icon>
                        <!-- </v-avatar> -->
                      </a>
                    </template>
                    <span>Email Me!</span>
                  </v-tooltip>

                  <v-tooltip top class="justify-center">
                    <template v-slot:activator="{ on, attrs }">
                      <a href="https://www.linkedin.com/in/benjamin-philipenko-9145231b8/" style="text-decoration:none; color:inherit" link v-bind="attrs" v-on="on">
                        <!-- <v-avatar color="indigo" size="65"> -->
                          <v-icon x-large class="display-3 pl-6 pr-6">
                            mdi-linkedin
                          </v-icon>
                        <!-- </v-avatar> -->
                      </a>
                    </template>
                    <span>Check Out My LinkedIn Profile</span>
                  </v-tooltip>

                  <!-- <v-tooltip top class="justify-center">
                    <template v-slot:activator="{ on, attrs }">
                      <a href="https://github.com/Benjamin-Philipenko" style="text-decoration:none; color:inherit" link v-bind="attrs" v-on="on">
                          <v-icon x-large class="display-3 pl-6 pr-6">
                            mdi-github
                          </v-icon>
                      </a>
                    </template>
                    <span>Check Out My Github Projects</span>
                  </v-tooltip> -->

                </v-row>

                <br>

          </div>

            </v-card>

        <v-divider dark class="pt-12"></v-divider>
        <v-divider dark class="pb-12"></v-divider>

        <v-row class="pb-12 d-none d-xl-flex d-lg-flex d-md-flex" justify="center">

          <v-col md="auto">
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a href="https://vuejs.org/" v-bind="attrs" v-on="on">
                <v-img src="logo.png" eager contain max-width="200"></v-img>
              </a>
            </template>
            <span class="text-center">Click to View Vue.js Documentation</span>
          </v-tooltip>
          </v-col>

          <v-col md="auto">
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a href="https://vuetifyjs.com/en/" v-bind="attrs" v-on="on">
                <v-img src="vuetify logo.png" eager contain max-width="200"></v-img>
              </a>
            </template>
            <span>Click to View Vuetify Documentation</span>
          </v-tooltip>
          </v-col>

        </v-row>

        <v-row class="pb-12 d-none d-sm-flex d-md-none" justify="center">

            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a href="https://vuejs.org/" v-bind="attrs" v-on="on">
                <v-img src="logo.png" eager contain max-width="200"></v-img>
              </a>
            </template>
            <span class="text-center">Click to View Vue.js Documentation</span>
          </v-tooltip>

        </v-row>

        <v-row class="pb-12 d-none d-sm-flex d-md-none" justify="center">

          
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a href="https://vuetifyjs.com/en/" v-bind="attrs" v-on="on">
                <v-img src="vuetify logo.png" eager contain max-width="200"></v-img>
              </a>
            </template>
            <span>Click to View Vuetify Documentation</span>
          </v-tooltip>

        </v-row>

        <v-row class="pb-12 d-flex d-sm-none" justify="center">

            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a href="https://vuejs.org/" v-bind="attrs" v-on="on">
                <v-img src="logo.png" eager contain max-width="200"></v-img>
              </a>
            </template>
            <span class="text-center">Click to View Vue.js Documentation</span>
          </v-tooltip>

        </v-row>

        <v-row class="pb-12 d-flex d-sm-none" justify="center">

          
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a href="https://vuetifyjs.com/en/" v-bind="attrs" v-on="on">
                <v-img src="vuetify logo.png" eager contain max-width="200"></v-img>
              </a>
            </template>
            <span>Click to View Vuetify Documentation</span>
          </v-tooltip>

        </v-row>

          <h1 class="text-center white--text font-weight-thin pb-8">This Project was built using Vue.js and Vuetify</h1>
   
      </v-container>
</v-img>
    
</template>

<script>
  export default {
    name: 'ContactContent',

    data: () => ({
      
    }),
  }
</script>