<template>
  <v-app id="inspire">
    <v-app-bar
      app
      prominent
      src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
    >

      <div class="mx-auto pt-3 d-none d-sm-flex">
        <h2 class="font-weight-thin white--text text-center">Benjamin Philipenko
          <div><h6 class="font-weight-thin pl-1">Engineering Student | Developer</h6></div>
        </h2>
      </div>

      <div class="mx-auto pt-7 d-flex d-sm-none">
        <h3 class="font-weight-thin white--text text-center">Benjamin Philipenko
          <div><h6 class="font-weight-thin pl-1">Engineering Student | Developer</h6></div>
        </h3>
      </div>

        <template v-slot:extension>
          <v-tabs 
          dark
          centered
          grow
          >
            <v-tab
             v-for="link in links"
            :key="link"
            :to="link.to">
              <v-icon class="mr-2">{{ link.icon }}</v-icon> {{ link.title }}
            </v-tab>
          </v-tabs>
        </template>

    </v-app-bar>
    
        <v-main color="indigo">

      
      <transition name="view">
        <router-view></router-view>
      </transition>

        </v-main>

    <v-footer>
    <v-col
      class="text-center"
      cols="12"
    >

    <v-btn
      class="mx-4 mb-4"
      icon
    >
    <a href="mailto:benjaminphilipenko@gmail.com" style="text-decoration:none; color:inherit">
      <v-icon size="30px">
        mdi-email
      </v-icon>
    </a>
    </v-btn>

    <v-btn
      class="mx-4 mb-4"
      icon
    >
    <a href="https://www.linkedin.com/in/benjamin-philipenko-9145231b8/" style="text-decoration:none; color:inherit">
      <v-icon size="30px">
        mdi-linkedin
      </v-icon>
    </a>
    </v-btn>

    <!-- <v-btn
      class="mx-4 mb-4"
      icon
    >
    <a href="https://github.com/Benjamin-Philipenko" style="text-decoration:none; color:inherit">
      <v-icon size="30px">
        mdi-github
      </v-icon>
    </a>
    </v-btn> -->

    <v-divider></v-divider>
    
    <br>
    
      <strong>Benjamin Philipenko</strong> || {{ new Date().getFullYear() }} 
    
    </v-col>
  </v-footer>

    

  </v-app>
</template>

<script>
  export default {
    data: () => ({
      links: [
        { title: 'Home', icon: 'mdi-home', to: '/' },
        { title: 'Projects', icon: 'mdi-semantic-web', to: '/projects' },
        { title: 'Contact', icon: 'mdi-account-box-multiple', to: '/contact' }
        ]
    }),
  }
</script>

<style>

 @import "https://cdn.jsdelivr.net/npm/animate.css@3.5.1";

/* .page {
  position: fixed;
  width: inherit;
} */

.view-enter-active, .view-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease;
}

.view-enter-active {
  transition-delay: 0.5s;
}

.view-enter, .view-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.view-enter-to, .view-leave {
  opacity: 1;
  transform: translateY(0px);
}

</style>
