<template>
  <div>
    <Project2Content />
  </div>
</template>

<script>
// @ is an alias to /src
import Project2Content from '@/components/Project2Content.vue'

export default {
  name: 'Project2',
  components: {
    Project2Content
  }
}
</script>