<template>

  <v-img src="xS7RMf.jpg">
      <v-container>

        <v-sheet
          min-height="80vh"
          max-width="1400px"
          rounded="lg"
          color="grey lighten-5"
          elevation="24"
          class="mx-auto"
        >
        
        <div class="pt-4 pl-4 pb-4">

          <v-row justify="start" class="pt-4 pl-4">
                <v-btn
                color="indigo"
                dark
                large
                to="/projects"
                class="d-none d-xl-flex d-lg-flex d-md-flex d-sm-flex"
                >
                <v-icon
                class="ml-1"
                dark
                >
                mdi-arrow-left-bold
                </v-icon>
                Back to Projects
                </v-btn>
          </v-row>

          <v-row justify="center" class="pb-4">
                <v-btn
                color="indigo"
                dark
                to="/projects"
                class="d-flex d-sm-none"
                >
                <v-icon
                class="ml-1"
                dark
                >
                mdi-arrow-left-bold
                </v-icon>
                Back to Projects
                </v-btn>
            </v-row>
          
        </div>

          <v-divider></v-divider>

          <br>

          <v-row justify="center">
            <h1 class="text-center display-3 black--text font-weight-light d-none d-xl-flex d-lg-flex"> 
                 UVic Robotics Club - Rover Development
            </h1>
          </v-row>

          <v-row justify="center">
            <h1 class="text-center display-2 black--text font-weight-light d-none d-md-flex d-lg-none"> 
                UVic Robotics Club - Rover Development 
            </h1>
          </v-row>

          <v-row justify="center">
            <h1 class="text-center display-2 black--text font-weight-light d-none d-sm-flex d-md-none"> 
                UVic Robotics Club - Rover Development 
            </h1>
          </v-row>

          <v-row justify="center">
            <h1 class="text-center headline black--text font-weight-light d-flex d-sm-none"> 
                UVic Robotics Club - Rover Development 
            </h1>
          </v-row>
            
          <br>

          <v-divider></v-divider>

          <v-container>

            <div class="pt-4 d-none d-xl-flex d-lg-flex">
                  <v-card
                    class="mx-auto"
                    max-width="800"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="700"
                      src="Uvic robotics team photo.jpg"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>

                <div class="pt-4 d-none d-md-flex d-lg-none">
                  <v-card
                    class="mx-auto"
                    max-width="600"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="Uvic robotics team photo.jpg"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>

                <div class="pt-4 d-none d-sm-flex d-md-none">
                  <v-card
                    class="mx-auto"
                    max-width="500"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="Uvic robotics team photo.jpg"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>

                 <div class="pt-2 d-flex d-sm-none">
                  <v-card
                    class="mx-auto"
                    max-width="325"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="Uvic robotics team photo.jpg"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>

            <h2 class="mx-6 px-6 pt-8 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                The UVic Robotics Club is currently preparing to attend the 2022 Canadian International Rover Challenge in
                Drumheller, Alberta. The competition objectives are centered around the theme of a Mars Colony's nuclear power
                reactor complex suffering a catastrophic accident. The tasks our rover will accomplish will help contain the damage
                to the local environment and protect inhabitants from released radiation.
              </h2>
              <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                Some of the tasks our rover will have to perform include:
              </h2>
              <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
               • Travel a patrol route that has been made to inspect the nuclear reactors and complete a routine inspection. 
              </h2>
              <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                • Save and evacuate an astronaut from an area of dangerously spreading radioactive material, and 
                ensure the area is safe for human crew to continue cleanup and operation. 
              </h2>
              <h2 class="mx-12 px-12 pb-3 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                • Retrieve and analyze a soil sample to determine whether or not a prospective region meets the metrics 
                for future habitation and for scientific study. 
              </h2>
              <h2 class="mx-12 px-12 pb-12 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                •  Rover arm dexterity challenges.
              </h2>

            <div class="pb-12 d-none d-xl-flex d-lg-flex">
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-height="200"
                    max-width="560"
                  >
                    <v-img
                      src="robotics leg assembly.webp"
                      eager
                      contain
                    >
                    </v-img>
                  </v-card>
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-width="560"
                  >
                    <v-img
                      src="robotics deive train.webp"
                      eager
                      contain
                    >
                    </v-img>
                  </v-card>
                </div>

                <div class="pb-12 d-none d-md-flex d-lg-none">
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-width="400"
                  >
                    <v-img
                      src="robotics leg assembly.webp"
                      eager
                      contain
                      
                    >
                    </v-img>
                  </v-card>
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-width="400"
                  >
                    <v-img
                      src="robotics deive train.webp"
                      eager
                      contain
                      
                    >
                    </v-img>
                  </v-card>
                </div>

              <h2 class="mx-6 px-6 pb-12 font-weight-light black--text text-justify d-none d-xl-flex d-lg-flex d-md-flex">
                My current project with the club is developing code to add functionality for sending rover arm commands between 
                the base station and our runt rover (miniature rover prototype used for testing our codebase) using the 
                ROS framework (C++, Python), and the Flask framework (Python).   
              </h2>

              <div class="pb-12 d-none d-xl-flex d-lg-flex">
                  <v-card
                    class="mx-auto"
                    max-width="550"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="runt_rover_photo.jpg"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>

                <div class="pb-6 d-none d-md-flex d-lg-none">
                  <v-card
                    class="mx-auto"
                    max-width="400"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="runt_rover_photo.jpg"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>

                <h3 class="mx-6 pt-8 pb-2 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                The UVic Robotics Club is currently preparing to attend the 2022 Canadian International Rover Challenge in
                Drumheller, Alberta. The competition objectives are centered around the theme of a Mars Colony's nuclear power
                reactor complex suffering a catastrophic accident. The tasks our rover will accomplish will help contain the damage
                to the local environment and protect inhabitants from released radiation.
                </h3>

                <h3 class="mx-12 px-6 pb-2 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  Some of the tasks our rover will have to perform include:
                </h3>

                <h3 class="mx-12 px-6 pb-2 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  • Travel a patrol route that has been made to inspect the nuclear reactors and complete a routine inspection.
                </h3>

                <h3 class="mx-12 px-6 pb-2 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  • Save and evacuate an astronaut from an area of dangerously spreading radioactive material, and 
                ensure the area is safe for human crew to continue cleanup and operation. 
                </h3>

                <h3 class="mx-12 px-6 pb-2 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  • Retrieve and analyze a soil sample to determine whether or not a prospective region meets the metrics 
                for future habitation and for scientific study.
                </h3>

                <h3 class="mx-12 px-6 pb-6 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                  •  Rover arm dexterity challenges.
                </h3>  

            <div class="pb-6 d-none d-sm-flex d-md-none">
                  <v-card
                    class="mx-auto"
                    elevation="12"
                  >
                    <v-img
                      src="robotics leg assembly.webp"
                      eager
                      contain
                      max-width="300"
                    >
                    </v-img>
                  </v-card>
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-width="300"
                  >
                    <v-img
                      src="robotics deive train.webp"
                      eager
                      contain
                    >
                    </v-img>
                  </v-card>
                </div>

                <h3 class="mx-6 pt-4 pb-10 font-weight-light black--text text-justify d-none d-sm-flex d-md-none">
                My current project with the club is developing code to add functionality for sending rover arm commands between 
                the base station and our runt rover (miniature rover prototype used for testing our codebase) using the 
                ROS framework (C++, Python), and the Flask framework (Python).   
                </h3>

                <div class="pb-6 d-none d-sm-flex d-md-none">
                  <v-card
                    class="mx-auto"
                    max-width="300"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="runt_rover_photo.jpg"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>

            <h4 class="mx-2 pb-2 pt-8 font-weight-light black--text text-justify d-flex d-sm-none">
                  The UVic Robotics Club is currently preparing to attend the 2022 Canadian International Rover Challenge in
                Drumheller, Alberta. The competition objectives are centered around the theme of a Mars Colony's nuclear power
                reactor complex suffering a catastrophic accident. The tasks our rover will accomplish will help contain the damage
                to the local environment and protect inhabitants from released radiation. 
            </h4>
            <h4 class="mx-2 pb-2 font-weight-light black--text text-justify d-flex d-sm-none">
                  Some of the tasks our rover will have to perform include:
            </h4>
            <h4 class="mx-2 pb-2 font-weight-light black--text text-justify d-flex d-sm-none">
                  • Travel a patrol route that has been made to inspect the nuclear reactors and complete a routine inspection.
            </h4>
            <h4 class="mx-2 pb-2 font-weight-light black--text text-justify d-flex d-sm-none">
                  • Save and evacuate an astronaut from an area of dangerously spreading radioactive material, and 
                ensure the area is safe for human crew to continue cleanup and operation.
            </h4>
            <h4 class="mx-2 pb-2 font-weight-light black--text text-justify d-flex d-sm-none">
                  • Retrieve and analyze a soil sample to determine whether or not a prospective region meets the metrics 
                for future habitation and for scientific study.
            </h4>
            <h4 class="mx-2 pb-6 font-weight-light black--text text-justify d-flex d-sm-none">
                  •  Rover arm dexterity challenges.
            </h4>

            <div class="pb-6 d-flex d-sm-none">
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-width="200"
                  >
                    <v-img
                      src="robotics leg assembly.webp"
                      eager
                      contain
                    >
                    </v-img>
                  </v-card>
                  <v-card
                    class="mx-auto"
                    elevation="12"
                    max-width="200"
                  >
                    <v-img
                      src="robotics deive train.webp"
                      eager
                      contain
                    >
                    </v-img>
                  </v-card>
                </div>

                <h4 class="mx-2 pb-6 font-weight-light black--text text-justify d-flex d-sm-none">
                  My current project with the club is developing code to add functionality for sending rover arm commands between 
                  the base station and our runt rover (miniature rover prototype used for testing our codebase) using the 
                  ROS framework (C++, Python), and the Flask framework (Python).  
                </h4>

                <div class="pb-6 d-flex d-sm-none">
                  <v-card
                    class="mx-auto"
                    max-width="325"
                    elevation="12"
                    contain
                  >
                    <v-img
                      class="white--text align-end"
                      max-width="600"
                      src="runt_rover_photo.jpg"
                      eager
                    >
                    </v-img>
                  </v-card>
                </div>
              

          </v-container>

        </v-sheet>

      </v-container>
  </v-img>

</template>

<script>
  export default {
    name: 'Project3Content',

    data: () => ({
      
    }),
  }
</script>